<template>

    <div class="option-mask" v-click-outside="clickOutside">
            <div class="option-menu">
                <div v-for="(option, index) in options" :key="index" class="menu-item" @click="itemClicked(option.event)">
                    {{option.title}}
                </div>
            </div>
    </div>


</template>

<script>
import EventBus from "@/eventBus.js"
export default {
    props: ["options"],
    data () {
        return {
        }
    },
    methods: {
        clickOutside: function() {
            this.$emit('close')
        },
        itemClicked: function (event) {
            this.$emit(event)
        }
    },
    beforeMount () {
        EventBus.$emit("OPTIONS_OPENED")
    },
    mounted () {
         EventBus.$on("OPTIONS_OPENED", () => {
             this.$emit('close')
         })
    }
}


</script>

<style lang="scss" scoped>
@import '@/styles/abstracts/_mixins.scss';
@import '@/styles/abstracts/_variables.scss';

.option-mask {
        z-index: 999;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #ffffff99;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        opacity: 1;

    @include respond-to('medium') {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #ffffff99;
        z-index: 999;
        justify-content: flex-end;
    }

}

.option-menu {

    border-radius: 8px;
    border: 1px solid #4a4a4a20;
    background-color: white;
    box-shadow: $modal-shadow;
    overflow: hidden;
    min-width: 400px;  
    

    @include respond-to('medium') {
    position: absolute;
    right: 0;
    top:0;
    display: flex;
    flex-flow: column;
    align-items: center;
    min-width: auto;
    }

    .menu-item {
        width: 100%;
        padding: 1em 2em;
        font-weight: 700;
        text-align: center;
    }

    .menu-item:not(:first-child) {
    border-top: 1px solid #4a4a4a20;
  }
}


.no-touch .menu-item:hover {
  background-color: $hover-list-background;
  color: $accent-color;

}


</style>