<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">
 
        <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.confirmDelTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
        </div>

    <h3 v-if="recordingShow" class="modal-sub-header">
      {{$t('modal.confirmDelMsg1')}}
    </h3>

    <h3 v-else class="modal-sub-header">
      {{$t('modal.confirmDelMsg2')}}
    </h3>

  
    <div class="rec-names modal-content" v-if="recordingShow">

        <div v-for="(recording, index) in delcollection.Summary.Recordings" :key="index">

          <div class="rec-summary-name">
                <i class="material-icons icon card-icon"
                >
                play_circle_filled</i>
                <div class="rec-name">{{recording.Name}}</div>
          </div>
     </div>
    </div>

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('modal.cancel')}}
            </v-btn>

            <v-btn 
            class="focus-button"
            color="blue" 
            light
            @click="confirmDelete"
            >{{$t('modal.delete')}}</v-btn>

    </div>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>

export default {

data () {
    return {

    }
},
props: ['delcollection'],
methods : {
  confirmDelete: function () {

    this.$store.dispatch('DELETE_COLLECTION', this.delcollection.CollectionID).then(
      this.$emit('close')
    )

  }
},
watch: {

},
computed: {
        recordingShow () {

            if (this.delcollection.Summary.Recordings) { 
              return true
            } else {
              return false
            }
        }     
},

}
</script>

<style scoped>


.rec-names {
  display: flex; 
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 12px 3em;
}

h3 {
  width: 70%;
  margin: 0 auto;
}

.rec-summary-name {
  display: flex; 
  align-items: center;
  margin: 8px 12px 8px 0px;
  color: rgba(0, 0, 0, .33)
} 

.rec-name {
  font-size: 18px;
  color: rgba(0, 0, 0, .75)
}

.collection-form {
  margin: 20px 0 0 0;
}

</style>
