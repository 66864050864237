<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container modal-form"
        @click.stop="">
 
      <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.editCollTitle')}}</h2>
            <v-btn  icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
            </v-btn>
        </div>

    <div class="modal-content">

      <div class="form-content">
        <v-text-field
        label="Name"
        counter="30"
        v-model="collection.Name"
        append-icon="folder"  
        outlined
        @keyup.enter="updateCollection"
        ></v-text-field>

        <!-- <v-textarea
        label="Description"
        v-model="collection.Description"
        counter="250"
        outline
        @keyup.enter="submitNewCollection"
        ></v-textarea> -->
      </div>
          </div>

    <div class="button-row">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('modal.cancel')}}
            </v-btn>

            <v-btn 
            class="focus-button"
            color="blue" 
            light
            @click="updateCollection"
            :disabled="readyToSubmit"
            >
            {{$t('modal.ok')}}</v-btn>

    </div>


          </div>
        </div>
      </div>
  </transition>
</template>

<script>

export default {

data () {
    return {
      collection: null,
        // title: "", 
        // description: ""
    }
},
methods : {
  updateCollection: function () {

    if (this.collection.Name && this.collection.Name.length > 30) {
      return
    }

    if (this.collection.Description && this.collection.Description.length > 250) {
      return
    }
  
  
    const collectionPayload = {
      "Name" : this.collection.Name,
      "Description" : this.collection.Description,
    }

    const payload = {
      "collID": this.collection.CollectionID,
      "itemID": this.collection.ItemID,
      "collection": collectionPayload,
    }

    this.$store.dispatch('UPDATE_COLLECTION', payload)
    this.$emit('close')

  }
},
watch: {

},
computed: {
    // collection () {
    //   return this.$store.getters.GET_COLL_EDITING
    // },
    readyToSubmit () {
      return this.collection.Name ? false : true
    }
},
beforeDestroy () {
    this.$store.dispatch('CLEAR_COLL_EDITING')
  },
  created () {
    // create a copy of the recDetails object so that we can edit without it binding to our actual data
      this.collection = Object.assign({}, this.$store.getters.GET_COLL_EDITING)
      // this.date = this.coll.Date.substring(0,10)  
  }

}
</script>

<style scoped>



</style>
