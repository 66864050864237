<template>
<div>

  <app-new-collection v-if="showNewCollection"
  @close="showNewCollection = false"></app-new-collection>

  <app-edit-collection v-if="showEditCollection"
  @close="showEditCollection = false"></app-edit-collection>

  <app-delete-collection v-if="showDeleteCollection"
  @close="closeDelete"
  :delcollection="delCollection"
  ></app-delete-collection>

<transition appear name="slide-fade">

  <div class="list-grid collection-grid">

    <div class="list-menu">

      <div class="app-btn main-cta saylient-btn shdw" v-ripples @click="closingWarning">
        <div class="material-icons menu-icon">folder</div>
        <div class="menu-label">{{$t('home.newCollection')}}</div>
      </div>

    </div>

      <collection-card
      v-for="collection in filteredCollections"
      :collection="collection"
      :key="collection.CollectionID"
      @deleteStart="startDeleteCollection"
      @edit="editCollection"
    >
    </collection-card>
  </div>

    </transition>

    <div class="mobile-fab card-hover" v-if="false"
    @click="openNewCollection"
    >
      <div class="std-text">
          <i class="material-icons icon mobile-fab--icon">add</i>
          <p class="mobile-fab--label">New</p>

      </div>
    </div>

    <div class="bottom-menu">
      <v-btn dark @click="$router.go(-1)" color="#000000"><div class="material-icons">keyboard_arrow_left</div>Back</v-btn>
      <v-btn dark @click="openNewCollection" color="#000000"><div class="material-icons">add</div>New Collection</v-btn>
    </div>

    <transition appear name="slow-fade">
    <div class="description">

          <i
          @click="showHelp = !showHelp"
          class="material-icons icon desc-icon">help_outline</i>

          <div v-show="showHelp">
          <p>{{$t('helpText.homeColl')}}</p>
          </div>
    </div>
    </transition>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NewCollectionMD from './../components/collections/NewCollectionMD'
import EditCollectionMD from './../components/collections/EditCollectionMD'
import DeleteCollectionMD from './../components/collections/DeleteCollectionMD'
import CollectionCard from './../components/collections/CollectionCard'
// import CollectionCard2 from './../components/collections/CollectionCard2'
import EventBus from './../eventBus'

export default {

  name: 'home',
    computed : {
        ...mapGetters({
            collections : 'COLLECTIONS',
            userDetails : 'USER_DETAILS',
            accessDenied: 'ACCESS_DENIED',
            windowWidth: 'WIDTH',
        }),
        filteredCollections () {

          let displayList = this.collections

          if (this.collections) {

                displayList.sort(function (a,b) {
                    a = new Date(a.CreatedDate);
                    b = new Date(b.CreatedDate);
                    return a > b ? -1 : a < b ? 1 : 0;
                })

          }
          return displayList
        },
        isSmall () {
            return (this.windowWidth < 950) ? true : false
        },
        isMobile () {
            return (this.windowWidth < 600) ? true : false
        },
        displayText () {
          return this.userDetails.FirstName ? "Hi, " + this.userDetails.FirstName + ".": "Hi, there."
        }
  },
  data () {
        return {
          showNewCollection: false,
          showEditCollection: false,
          showDeleteCollection: false,
          delCollection: {},
          showHelp: true,
        }
  },
  mounted () {

    if (this.userDetails) {
      this.$store.dispatch('MY_COLLECTIONS')
    }

    EventBus.$on('CREATE_COLL', () => {
      this.showNewCollection = true
    })

  },
  beforeCreate () {

  },
  methods : {
    openNewCollection: function () {

      if (this.accessDenied) {
        EventBus.$emit('ACCESS_DENIED')
      } else {
        this.showNewCollection = true
      }

    },
    closingWarning: function () {
          EventBus.$emit('SHOW_MESSAGE', 'Uploads are no longer allowed', 'red')
        },
    viewCollection: function (id) {
      this.$router.push({ name: 'collection', params: { collID: id } })
    },
    startDeleteCollection: function (collection) {

      this.showDeleteCollection = true
      this.delCollection = collection

    },
    editCollection: function (collection) {

      this.$store.dispatch('SET_COLL_EDITING', collection)
      this.showEditCollection = true

    },
    closeDelete: function () {

      this.deleteCollection = {}
      this.showDeleteCollection = false

    },
  },
  components: {
        appNewCollection: NewCollectionMD,
        appEditCollection: EditCollectionMD,
        // appCollectionCard: CollectionCard,
        appDeleteCollection: DeleteCollectionMD,
        CollectionCard: CollectionCard
  },
  watch : {
    userDetails: function () {

      if (this.userDetails) {
        this.$store.dispatch('MY_COLLECTIONS')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/abstracts/_mixins.scss';


.collection-grid {
    padding-top: 2em;

    @include respond-to('medium') {
        padding-top: 1em;
    }


}

// #welcome-banner {
//   display: flex;
//   margin: 0 auto;
//   justify-content: center;
// }

// .shrinkmargin {
//   padding-top: 24px;
// }

// .welcome-icon {
//   margin: 0 auto;
//   font-size: 35px;
//   animation: pulse 1s linear infinite;
// }

// .sub-title{
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   width: 45%;
//   margin: 40px auto 0 auto;
// }

// .sub-title div{
//   background-color: #34343a;
//   flex-grow: 2;
//   height: 1px;
//   margin: 8px;
// }

// .grid-card .coll-recordings {
//   text-align: right;
//   margin: 0 20px;
//   display:flex;
//   flex-direction: row;
//   justify-content: flex-end;
// }

// .desc-logo{
//    animation: pulse 1s linear infinite;
// }

// @media (min-width: 1px) and (max-width: 900px) {

//   .add-card {
//     opacity: 0;
//     visibility: hidden;
//   }

// }

// @keyframes pulse {
//   0% { transform: scale(1); }
//   50% { transform: scale(0.9); }
//   100% { transform: scale(1); }
// }


</style>
