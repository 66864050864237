<template>
    <div class="card collection-card">

      <div class="card-top-row" @click="goToCollection">

        <div class="card-cta" @click="expand = !expand" @click.stop="">
          <div v-ripples class="icon-btn saylient-btn material-icons accent-icon" :class="{'rotate' : expand}">{{expand ? "unfold_less" : "unfold_more"}}</div>
        </div>

        <div class="material-icons">folder</div>

        <div class="card-title two-line">
          <div class="title-name">{{collection.Name}}</div>
          <div class="subtitle">{{$tc('home.recordings', recordingCount)}}</div>
        </div>

        <div class="app-btn saylient-btn no-emphasis-btn small" @click="closingWarning" @click.stop="">
          <div class="material-icons">add</div>
          <div class="hide-x-small">{{$t('home.addRecording')}}</div>
        </div>

        <div class="card-cta" @click="showOptions = !showOptions" @click.stop="">
          <div class="icon-btn saylient-btn material-icons">more_vert</div>
          <option-menu v-if="showOptions" :options="options"
          @close="showOptions = false"
          @renameColl="renameColl"
          @deleteColl="deleteColl"
          ></option-menu>
        </div>

      </div>

      <div class="expand-section" :class="{'expand' : expand}">

        <div class="recording-list">
          <div
          class="recording-list-item"
          v-ripples v-for="(recording, index) in collection.Summary.Recordings" :key="index"
          @click="goToRec(recording)"
          >

            <div class="material-icons list-item-icon">
              {{recording.MediaType.startsWith("video") ? 'personal_video' : 'mic'}}
            </div>

            <div>{{recording.Name}}</div>

            <div v-ripples class="appear-hover material-icons">arrow_forward_ios</div>

          </div>
        </div>

      </div>

      <new-rec v-if="showNewRec"
      :collection="collection"
      :recordingCount="recordingCount"
      :collectionID="collection.CollectionID"
      @close="showNewRec = false"
      ></new-rec>

    </div>
</template>

<script>
import OptionMenu from './../UI/OptionMenu'
import NewRecMD from "@/components/collections/NewRecMD"
import EventBus from './../../eventBus'

export default {
  props: ["collection"],
  data () {
    return {
      expand: false,
      showOptions: false,
      showNewRec: false,
      options: [
        {title: "Rename", event: "renameColl"},
        {title: "Delete", event: "deleteColl"},
      ]
    }
  },
  computed : {
    recordingCount : function () {
      return this.collection.Summary.Recordings ? this.collection.Summary.Recordings.length : 0
    }
  },
  methods: {
    renameColl: function () {
      this.$emit('edit', this.collection)
    },
    closingWarning: function () {
          EventBus.$emit('SHOW_MESSAGE', 'Uploads are no longer allowed', 'red')
        },
    deleteColl: function () {
      this.$emit('deleteStart', this.collection)
    },
    goToCollection: function () {
      this.$router.push({ name: 'collection', params: { collID: this.collection.CollectionID } })
    },
    goToRec: function(recDetails) {
      this.$router.push({ name: 'recording', params: { collID: this.collection.CollectionID, recID : recDetails.ItemID } })
    },
    addNewRec: function () {
      this.showNewRec = true
    },

  },
  components : {
    OptionMenu,
    NewRec: NewRecMD
  }
}

</script>

<style lang="scss" scoped>
@import './../../styles/components/_cards.scss';
@import './../../styles/components/_buttons.scss';


.recording-list {
  display: flex;
  flex-flow: column-reverse;


  .recording-list-item {
    border-top: 1px solid #4a4a4a20;
  }


}

.recording-list-item {
  padding: 1em 2em;
  font-size: 1.1em;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .appear-hover {
    font-size: 0.8em;
    opacity: 0;
    margin-left: 1em;
  }

  .list-item-icon {
    margin-right: 0.5em;
    font-size: 1.2em;
  }

}

.no-touch .recording-list-item:hover {
  background-color: #4a4a4a10;
  color: $accent-color;

  .appear-hover {
    opacity: 1;
  }

}

</style>

