<template>
      <transition name="modal">
    <div class="modal-mask"
        @click="$emit('close')"
        >
      <div class="modal-wrapper">
        <div class="modal-container"
        @click.stop="">
 
    <div class="modal-title-bar">
            <h2 class="modal-header">{{$t('modal.newCollTitle')}}</h2>
            <v-btn icon @click="$emit('close')">
            <div class="material-icons modal-title-close">clear</div>
        </v-btn>
    </div>

    <div class="modal-content" v-on:submit.prevent="onSubmit">
        <v-text-field
        label="Name"
        counter="30"
        v-model="title"
        outlined
        append-icon="folder"
        @keyup.enter="submitNewCollection"
        ></v-text-field>

    </div>

        <div class="button-row modal-controls">
            <v-btn
            @click="$emit('close')"
            >
            {{$t('modal.cancel')}}
            </v-btn>

            <v-btn 
            class="focus-button"
            color="blue" 
            light
            @click="submitNewCollection"
            :disabled="readyToSubmit"
            >{{$t('modal.ok')}}</v-btn>

    </div>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>

export default {

data () {
    return {
        title: "", 
        description: ""
    }
},
methods : {
  submitNewCollection: function () {
  
    if (this.title.length > 30 || this.description.length > 250) {
      return
    }

    const collection = {
      "Name" : this.title,
      "Description" : this.description,
    }

    this.$store.dispatch('CREATE_COLLECTION', collection)

    this.$emit('close')
  }
},
watch: {
    newCollection : function () {
      this.$store.dispatch('CHANGE_HEIGHT', 100)
    }
},
computed: {
    newCollection () {
      return this.$store.getters.GET_NEWCOLL
    },
    readyToSubmit () {
      return this.title ? false : true
    }
},
}
</script>

